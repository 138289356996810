/**
 * @fileoverview
 * @author
 */
import { DisplayableMessageFormat } from '@s/components/atom/WidgetMessageConfig';
import { WidgetRequestError } from '@s/components/atom/WidgetContext';
import { Auth } from '@w/domain/entities/Auth';
import { MessageFormat } from '@s/domain/entity/MessageFormat';
import { MediaCache } from '@s/domain/entity/MediaCache';
import {
  WidgetConfigurationCustomize,
  defaultWidgetConfiguration,
} from '@s/domain/values/WidgetConfiguration';

export interface MessageState {
  typing: { visible: boolean };
  messages: DisplayableMessageFormat[];
  mediaCache: MediaCache;
  loading: boolean;
  error: WidgetRequestError;
  shouldTransferWelcomeMessage: boolean;
  isCustomerImageUploadEnabled: boolean;
}

export interface ChatState {
  open: boolean;
  loading: boolean;
  inputLoading: boolean;
  isOperatorChatting: boolean;
  shouldDisplayMiniFloat: boolean;
}

export interface AuthState {
  auth: Auth;
  previewToken?: string;
}

export type ReduxState = {
  message: MessageState;
  chat: ChatState;
  auth: AuthState;
  env: {
    persistency: 'local' | 'session';
    projectId: string;
    tenantId: string;
    tenantName: string;
    welcomeMessage: MessageFormat | null;
    userData: { [key: string]: string };
    customization: WidgetConfigurationCustomize;
  };
};

export const initialState: ReduxState = {
  auth: { auth: { token: '', claims: {} } },
  chat: {
    open: false,
    loading: true,
    inputLoading: true,
    isOperatorChatting: false,
    shouldDisplayMiniFloat: false,
  },
  env: {
    projectId: '',
    tenantId: '',
    tenantName: '',
    welcomeMessage: null,
    persistency: 'local',
    userData: {},
    customization: defaultWidgetConfiguration.customization,
  },
  message: {
    shouldTransferWelcomeMessage: false,
    typing: { visible: false },
    isCustomerImageUploadEnabled: false,
    messages: [],
    mediaCache: {},
    loading: false,
    error: {
      uuid: [],
    },
  },
};
