/**
 * @fileOverview
 * @name reducer.ts<env>
 * @author Taketoshi Aono
 * @license
 */

import { welcomeMessagePrepared, envInitialized, customizationFetched } from './action';
import produce from 'immer';
import { staticConfig, AimWidgetStaticConfig } from '@w/config';
import { ReduxState } from '@w/domain/entities/State';
import { convertToWelcomeMessageToWidgetMessageFormat } from '@w/application/WelcomeMessage';
import { defaultWidgetConfiguration } from '@s/domain/values';

type ActionType = ReturnType<
  typeof welcomeMessagePrepared | typeof envInitialized | typeof customizationFetched
>;

export default (
  state: ReduxState['env'] = {
    projectId: '',
    tenantId: '',
    tenantName: '',
    welcomeMessage: null,
    persistency: 'local',
    userData: {},
    customization: defaultWidgetConfiguration.customization,
  },
  action: ActionType
) => {
  return produce(state, state => {
    switch (action.type) {
      case 'CUSTOMIZATION_FETCHED':
        state.customization = action.payload;
        return;
      case 'AIM_WIDGET_ENV_INITIALIZED':
        (Object.keys(staticConfig) as (keyof AimWidgetStaticConfig)[]).forEach(
          (k: keyof AimWidgetStaticConfig) => {
            const p = staticConfig[k];
            if (typeof p === 'string' && p.includes('TENANT_NAME')) {
              (staticConfig[k] as string) = p.replace(/TENANT_NAME/g, action.payload.tenantName);
            }
          }
        );
        Object.assign(state, { persistency: 'local', ...action.payload });
        return;
      case 'AIM_WIDGET_WELCOME_MESSAGE_PREPARED':
        state.welcomeMessage = convertToWelcomeMessageToWidgetMessageFormat(
          action.payload.messages,
          action.payload.textInputState
        );
        return;
      default:
        return;
    }
  });
};
