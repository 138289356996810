export const MASKING_REGEXS = [
  /(?:0\d-\d{4}-\d{4})|(?:0\d{3}-\d{2}-\d{4})|(?:\(0\d\)\d{4}-\d{4})|(?:\(0\d{3}\)\d{2}-\d{4})|(?:(070|080|090)-\d{4}-\d{4})|(?:050-\d{4}-\d{4})|(?:0120-\d{3}-\d{3})|(?:\d{12}|\d{11}|\d{10})/g,
  /[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*/g,
  /[a-zA-Z]{2}\d{7}/g,
];
const REPLACED_DOTS = Array(10).join('\u2022');
export const maskingTextMessageValue = (message?: string) => {
  return message
    ? MASKING_REGEXS.reduce((message, regexp) => {
        return message.replace(regexp, REPLACED_DOTS);
      }, message)
    : message;
};
